<template>
  <div>
    <ConfirmPopup></ConfirmPopup>
    <Dialog
      :header="
        schemeId == null
          ? $t('schemes.addNewScheme')
          : $t('schemes.updateScheme')
      "
      v-model:visible="viewDialog"
      :draggable="false"
      :modal="true"
      :style="{ width: '60vw' }"
    >
      <div class="grid">
        <span class="col-12 text-lg font-bold mb-2" style="margin-top: 0">
          {{ $t("schemes.schemeTitle") }}
        </span>
        <div class="field p-fluid col-12 md:col-6 m-0">
          <InputText
            id="schemeName"
            :disabled="scheme.status"
            type="text"
            :class="{ 'p-invalid': v$.scheme.title.$invalid && submitted }"
            v-model="v$.scheme.title.$model"
            aria-describedby="schemeName-error"
            :placeholder="$t('schemes.schemeName') + ' *'"
          />
          <!-- <small
            v-if="
              (v$.scheme.title.$invalid && submitted) ||
              v$.scheme.title.$pending.$response
            "
            class="p-error"
            >{{
              v$.scheme.title.required.$message.replace(
                "[value]",
                $t("schemes.schemeName")
              )
            }}</small
          > -->
          <ul
            v-if="v$.scheme.title.$error && submitted"
            class="p-error text-xs pl-3"
          >
            <li v-for="(error, index) of v$.scheme.title.$errors" :key="index">
              {{ error.$message.replace("[value]", $t("schemes.schemeName")) }}
            </li>
          </ul>
        </div>

        <div class="col-12 xl:col-6 pl-3 grid grid-no-gutter pt-0">
          <div class="col-3 align-self-center">
            <label for="enabled">{{ $t("global.status") }}:</label>
          </div>
          <div class="col-2 col-offset-1 align-self-center">
            <p>{{ $t("global.inactive") }}</p>
          </div>
          <div class="col-2 col-offset-1 align-self-center">
            <InputSwitch @change="changeStatus" :modelValue="scheme.status" />
          </div>
          <div class="col-2 col-offset-1 align-self-center">
            <p>{{ $t("global.active") }}</p>
          </div>
        </div>
      </div>
      <div class="grid grid-nogutter mb-2">
        <div class="col-12 md:col-3 mb-2 align-self-center font-bold">
          <p>{{ $t("schemes.greekTitle") }}:</p>
        </div>
        <div class="col-12 md:col-5 p-fluid">
          <div class="p-field">
            <span class="p-float-label">
              <InputText
                :disabled="Boolean(scheme.status)"
                v-model="v$.scheme.schemeTitle.$model"
                type="text"
                :class="{
                  'p-invalid': v$.scheme.schemeTitle.$invalid && submitted,
                }"
              />
            </span>
          </div>
        </div>
        <ul
          v-if="v$.scheme.schemeTitle.$error && submitted"
          class="p-error text-xs col-12 md:col-offset-3 md:col-9 pl-3"
        >
          <li
            v-for="(error, index) of v$.scheme.schemeTitle.$errors"
            :key="index"
          >
            {{ error.$message.replace("[value]", $t("schemes.greekTitle")) }}
          </li>
        </ul>
      </div>
      <div class="grid grid-nogutter mb-2">
        <div class="col-12 md:col-3 mb-2 align-self-center font-bold">
          <p>{{ $t("schemes.englishTitle") }}:</p>
        </div>
        <div class="col-12 md:col-5 p-fluid">
          <div class="p-field">
            <span class="p-float-label">
              <InputText
                :disabled="Boolean(scheme.status)"
                v-model="v$.scheme.schemeTitleEn.$model"
                type="text"
                :class="{
                  'p-invalid': v$.scheme.schemeTitleEn.$invalid && submitted,
                }"
              />
            </span>
          </div>
        </div>
        <ul
          v-if="v$.scheme.schemeTitleEn.$error && submitted"
          class="p-error text-xs col-12 md:col-offset-3 md:col-9 pl-3"
        >
          <li
            v-for="(error, index) of v$.scheme.schemeTitleEn.$errors"
            :key="index"
          >
            {{ error.$message.replace("[value]", $t("schemes.englishTitle")) }}
          </li>
        </ul>
      </div>
      <div class="grid grid-nogutter mb-2">
        <div class="col-12 md:col-3 mb-2 align-self-center font-bold">
          <p>{{ $t("schemes.chineseTitle") }}:</p>
        </div>
        <div class="col-12 md:col-5 p-fluid">
          <div class="p-field">
            <span class="p-float-label">
              <InputText
                :disabled="Boolean(scheme.status)"
                v-model="v$.scheme.schemeTitleZh.$model"
                type="text"
                :class="{
                  'p-invalid': v$.scheme.schemeTitleZh.$invalid && submitted,
                }"
              />
            </span>
          </div>
        </div>
        <ul
          v-if="v$.scheme.schemeTitleZh.$error && submitted"
          class="p-error text-xs col-12 md:col-offset-3 md:col-9 pl-3"
        >
          <li
            v-for="(error, index) of v$.scheme.schemeTitleZh.$errors"
            :key="index"
          >
            {{ error.$message.replace("[value]", $t("schemes.chineseTitle")) }}
          </li>
        </ul>
      </div>
      <!-- <div class="grid grid-nogutter mb-2">
        <div class="col-12 md:col-3 mb-2 align-self-center font-bold">
          <p>{{ $t("schemes.certificationType") }}:</p>
        </div>
        <div class="col-12 md:col-5 p-fluid">
          <div class="p-field">
            <span class="p-float-label">
              <Dropdown
                :disabled="Boolean(scheme.status)"
                id="dropdown"
                v-model="v$.scheme.schemeTitle.$model"
                :options="dropdown"
              />
            </span>
          </div>
        </div>
         <ul
          v-if="v$.scheme.schemeTitle.$error && submitted"
          class="p-error text-xs col-12 md:col-offset-3 md:col-9 pl-3"
        >
          <li v-for="(error, index) of v$.scheme.schemeTitle.$errors" :key="index">
            {{ error.$message.replace("[value]", $t("schemes.certificationType")) }}
          </li>
        </ul>
      </div> -->
      <div class="grid grid-nogutter">
        <div class="col-12 md:col-3 align-self-center font-bold mb-2">
          <p>{{ $t("schemes.expirationPeriod") }}:</p>
        </div>
        <div class="col-12 md:col-5 p-fluid">
          <div class="p-field">
            <span class="p-float-label">
              <Dropdown
                v-model="v$.scheme.expirationPeriod.$model"
                :disabled="Boolean(scheme.status)"
                :options="expOptions"
                optionLabel="title"
                optionValue="value"
                :class="{
                  'p-invalid': v$.scheme.expirationPeriod.$invalid && submitted,
                }"
              />
            </span>
          </div>
        </div>
        <ul
          v-if="v$.scheme.expirationPeriod.$error && submitted"
          class="p-error text-xs col-12 md:col-offset-3 md:col-9 pl-3"
        >
          <li
            v-for="(error, index) of v$.scheme.expirationPeriod.$errors"
            :key="index"
          >
            {{
              error.$message.replace("[value]", $t("schemes.expirationPeriod"))
            }}
          </li>
        </ul>
      </div>
      <Divider />
      <div class="grid flex-column grid-nogutter">
        <div class="grid grid-nogutter col-12">
          <span
            class="col-4 text-lg font-bold align-self-center"
            style="margin-top: 0"
          >
            {{ $t("schemes.instructions") }}
          </span>

          <div class="col-8 mb-2 text-right">
            <Button
              :label="$t('schemes.addInstructionRule')"
              autofocus
              :disabled="scheme.status"
              icon="mdi mdi-plus-circle-outline"
              iconPos="left"
              @click="addAssessmentInstruction()"
              class="p-button-sm"
            />
          </div>
        </div>
        <div class="col-12">
          <div class="grid grid-nogutter">
            <div class="col-11 p-fluid">
              <InputText
                id="instructionLabel"
                :disabled="scheme.status"
                type="text"
                v-model="v$.scheme.instructionsForAssessmentLabel.$model"
                :class="{
                  'p-invalid':
                    v$.scheme.instructionsForAssessmentLabel.$invalid &&
                    submitted,
                }"
                aria-describedby="instructionLabel-error"
                :placeholder="$t('schemes.instrutionLabel') + ' *'"
              />
              <!-- <small
                v-if="
                  (v$.scheme.instructionsForAssessmentLabel.$invalid &&
                    submitted) ||
                  v$.scheme.instructionsForAssessmentLabel.$pending.$response
                "
                class="p-error"
                >{{
                  v$.scheme.instructionsForAssessmentLabel.required.$message.replace(
                    "[value]",
                    $t("schemes.instrutionLabel")
                  )
                }}</small
              > -->
            </div>
            <div class="col-1 text-center">
              <Button
                icon="mdi mdi-close-circle mdi-18px"
                :disabled="scheme.status"
                class="p-button-rounded p-button-secondary p-button-text mr-2 mb-2"
                @click="refreshInstruction()"
              />
            </div>
            <ul
              v-if="
                v$.scheme.instructionsForAssessmentLabel.$error && submitted
              "
              class="p-error text-xs col-10 pl-3 mt-0"
            >
              <li
                v-for="(error, index) of v$.scheme
                  .instructionsForAssessmentLabel.$errors"
                :key="index"
              >
                {{
                  error.$message.replace(
                    "[value]",
                    $t("schemes.instrutionLabel")
                  )
                }}
              </li>
            </ul>
          </div>
        </div>

        <div
          class="col-12"
          v-for="(instruction, index) in scheme.instructionsForAssessment"
          :key="index"
        >
          <InstructionRule
            :instructionsForAssessmentValue="scheme.instructionsForAssessment"
            :instructionValue="instruction"
            :indexValue="index"
            :submittedValue="submitted"
            :schemeValue="scheme"
          ></InstructionRule>
        </div>

        <!-- <div class="col">
            <Button
              :label="$t('schemes.addInstruction')"
              autofocus
              @click="addInstruction()"
              :disabled="scheme.instructionsForAssessmentLabel != null"
            />
          </div> -->

        <span class="col-12 text-lg font-bold mb-2 mt-5" style="margin-top: 0">
          {{ $t("schemes.scoreRequirements") }}
        </span>
        <div class="col-12">
          <div class="grid grid-nogutter">
            <div class="col-11 p-fluid">
              <InputText
                id="scoreRequirementLabel"
                type="text"
                :disabled="scheme.status"
                v-model="v$.scheme.scoreRequirementLabel.$model"
                aria-describedby="roleName-error"
                :class="{
                  'p-invalid':
                    v$.scheme.scoreRequirementLabel.$invalid && submitted,
                }"
                :placeholder="$t('schemes.scoreRequirementLabel') + ' *'"
              />
              <!-- <small
                v-if="
                  (v$.scheme.scoreRequirementLabel.$invalid && submitted) ||
                  v$.scheme.scoreRequirementLabel.$pending.$response
                "
                class="p-error"
                >{{
                  v$.scheme.scoreRequirementLabel.required.$message.replace(
                    "[value]",
                    $t("schemes.scoreRequirementLabel")
                  )
                }}</small
              > -->
            </div>
            <div class="col-1 text-center">
              <Button
                icon="mdi mdi-close-circle mdi-18px"
                :disabled="scheme.status"
                class="p-button-rounded p-button-secondary p-button-text mr-2 mb-2"
                @click="refreshScoreRequiremntLabel()"
              />
            </div>
            <ul
              v-if="v$.scheme.scoreRequirementLabel.$error && submitted"
              class="p-error text-xs col-10 pl-3 mt-0"
            >
              <li
                v-for="(error, index) of v$.scheme.scoreRequirementLabel
                  .$errors"
                :key="index"
              >
                {{
                  error.$message.replace(
                    "[value]",
                    $t("schemes.scoreRequirementLabel")
                  )
                }}
              </li>
            </ul>
          </div>
        </div>

        <div class="col-12 mb-5">
          <div class="grid grid-nogutter">
            <div class="col-10 col-offset-1 p-fluid">
              <InputText
                type="text"
                :disabled="scheme.status"
                v-model="v$.scheme.scoreRequirement.$model"
                :class="{
                  'p-invalid': v$.scheme.scoreRequirement.$invalid && submitted,
                }"
                :placeholder="$t('schemes.scoreRequirementRule') + ' *'"
              />
              <!-- <small
                v-if="
                  (v$.scheme.scoreRequirement.$invalid && submitted) ||
                  v$.scheme.scoreRequirement.$pending.$response
                "
                class="p-error"
                >{{
                  v$.scheme.scoreRequirement.required.$message.replace(
                    "[value]",
                    $t("schemes.scoreRequirementRule")
                  )
                }}</small
              > -->
            </div>
            <div class="col-1 text-center">
              <Button
                :disabled="scheme.status"
                icon="mdi mdi-close-circle mdi-18px"
                class="p-button-rounded p-button-secondary p-button-text mr-2 mb-2"
                @click="refreshScoreRequiremnt()"
              />
            </div>
            <ul
              v-if="v$.scheme.scoreRequirement.$error && submitted"
              class="p-error text-xs col-10 col-offset-1 pl-3 mt-0"
            >
              <li
                v-for="(error, index) of v$.scheme.scoreRequirement.$errors"
                :key="index"
              >
                {{
                  error.$message.replace(
                    "[value]",
                    $t("schemes.scoreRequirementRule")
                  )
                }}
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="col-10 p-fluid grid">
          <div class="col-6">
            <Button
              :label="$t('schemes.addScoreRequirements')"
              autofocus
              :disabled="scheme.scoreRequirementLabel != null"
              @click="addScoreRequirement()"
            />
          </div>
        </div> -->
        <!--  -->
        <span class="col-12 text-lg font-bold mb-2" style="margin-top: 0">
          {{ $t("schemes.assessmentCriteria") }}
        </span>
        <div class="col-12">
          <div class="grid grid-nogutter">
            <div class="col-11 p-fluid">
              <InputText
                id="assessmentCriteria"
                type="text"
                :disabled="scheme.status"
                v-model="v$.scheme.assessmentCriteriaLabel.$model"
                :class="{
                  'p-invalid':
                    v$.scheme.assessmentCriteriaLabel.$invalid && submitted,
                }"
                aria-describedby="roleName-error"
                :placeholder="$t('schemes.assessmentCriteriaLabel') + ' *'"
              />
              <!-- <small
                v-if="
                  (v$.scheme.assessmentCriteriaLabel.$invalid && submitted) ||
                  v$.scheme.assessmentCriteriaLabel.$pending.$response
                "
                class="p-error"
                >{{
                  v$.scheme.assessmentCriteriaLabel.required.$message.replace(
                    "[value]",
                    $t("schemes.assessmentCriteriaLabel")
                  )
                }}</small
              > -->
            </div>
            <div class="col-1 text-center">
              <Button
                :disabled="scheme.status"
                icon="mdi mdi-close-circle mdi-18px"
                class="p-button-rounded p-button-secondary p-button-text mr-2 mb-2"
                @click="refreshAssessmentCriteriaLabel()"
              />
            </div>
            <ul
              v-if="v$.scheme.assessmentCriteriaLabel.$error && submitted"
              class="p-error text-xs col-11 pl-3 mt-0"
            >
              <li
                v-for="(error, index) of v$.scheme.assessmentCriteriaLabel
                  .$errors"
                :key="index"
              >
                {{
                  error.$message.replace(
                    "[value]",
                    $t("schemes.assessmentCriteriaLabel")
                  )
                }}
              </li>
            </ul>
          </div>
        </div>

        <div class="col-12 mb-5">
          <div class="grid grid-nogutter">
            <div class="col-10 col-offset-1 p-fluid">
              <InputText
                type="text"
                :disabled="scheme.status"
                :placeholder="$t('schemes.assessmentCriteriaRule') + ' *'"
                v-model="v$.scheme.assessmentCriteria.$model"
                :class="{
                  'p-invalid':
                    v$.scheme.assessmentCriteria.$invalid && submitted,
                }"
              />
              <!-- <small
                v-if="
                  (v$.scheme.assessmentCriteria.$invalid && submitted) ||
                  v$.scheme.assessmentCriteria.$pending.$response
                "
                class="p-error"
                >{{
                  v$.scheme.assessmentCriteria.required.$message.replace(
                    "[value]",
                    $t("schemes.assessmentCriteriaRule")
                  )
                }}</small
              > -->
            </div>
            <div class="col-1 text-center">
              <Button
                :disabled="scheme.status"
                icon="mdi mdi-close-circle mdi-18px"
                class="p-button-rounded p-button-secondary p-button-text mr-2 mb-2"
                @click="refreshAssessmentCriteria()"
              />
            </div>
            <ul
              v-if="v$.scheme.assessmentCriteria.$error && submitted"
              class="p-error text-xs col-10 col-offset-1 pl-3 mt-0"
            >
              <li
                v-for="(error, index) of v$.scheme.assessmentCriteria.$errors"
                :key="index"
              >
                {{
                  error.$message.replace(
                    "[value]",
                    $t("schemes.assessmentCriteriaRule")
                  )
                }}
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="col-10 p-fluid grid">
          <div class="col-6">
            <Button
              :label="$t('schemes.addAssessmentCriteria')"
              autofocus
              :disabled="scheme.assessmentCriteriaLabel != null"
              @click="addAssessmentCriteria()"
            />
          </div>
        </div> -->
        <Divider />
        <span class="col-12 text-lg font-bold mb-2 mt-5" style="margin-top: 0">
          {{ $t("schemes.scoreSheet") }}
        </span>
        <div class="col-12 mb-4">
          <div class="grid grid-nogutter">
            <div class="col-11 p-fluid">
              <InputText
                type="text"
                :disabled="scheme.status"
                :placeholder="$t('schemes.scoreSheetLabel') + ' *'"
                v-model="v$.scheme.scoreSheetLabel.$model"
                :class="{
                  'p-invalid': v$.scheme.scoreSheetLabel.$invalid && submitted,
                }"
              />
              <!-- <small
                v-if="
                  (v$.scheme.scoreSheetLabel.$invalid && submitted) ||
                  v$.scheme.scoreSheetLabel.$pending.$response
                "
                class="p-error"
                >{{
                  v$.scheme.scoreSheetLabel.required.$message.replace(
                    "[value]",
                    $t("schemes.scoreSheet")
                  )
                }}</small
              > -->
            </div>
            <div class="col-1 text-center">
              <Button
                :disabled="scheme.status"
                icon="mdi mdi-close-circle mdi-18px"
                class="p-button-rounded p-button-secondary p-button-text mr-2 mb-2"
                @click="refreshScoreSheetLabel()"
              />
            </div>
            <ul
              v-if="v$.scheme.scoreSheetLabel.$error && submitted"
              class="p-error text-xs col-11 pl-3 mt-0"
            >
              <li
                v-for="(error, index) of v$.scheme.scoreSheetLabel.$errors"
                :key="index"
              >
                {{
                  error.$message.replace("[value]", $t("schemes.scoreSheet"))
                }}
              </li>
            </ul>
          </div>
        </div>
        <div class="grid grid-nogutter mb-5">
          <div class="grid grid-nogutter col-6">
            <span class="col-12 text-lg font-bold mb-2">
              {{ $t("schemes.totalPoints") }}
            </span>
            <div class="col-10 md:col-6">
              <div class="p-fluid">
                <InputText
                  type="number"
                  :placeholder="$t('schemes.totalPoints')"
                  v-model="scheme.totalPoints"
                  :readonly="true"
                />
              </div>
            </div>
          </div>
          <div class="grid grid-nogutter col-6">
            <h4 class="col-12 text-lg font-bold mb-2">
              {{ $t("schemes.passPoints") }}
            </h4>
            <div class="col-10 md:col-6">
              <div class="p-fluid">
                <InputNumber
                  :disabled="scheme.status"
                  :placeholder="$t('schemes.passPoints') + ' *'"
                  v-model.number="v$.scheme.passPoints.$model"
                  :class="{
                    'p-invalid': v$.scheme.passPoints.$invalid && submitted,
                  }"
                  :min="0"
                  :max="scheme.totalPoints"
                />
                <!-- <span v-if="v$.scheme.passPoints.$error && submitted">
                  <span
                    id="email-error"
                    v-for="(error, index) of v$.scheme.passPoints.$errors"
                    :key="index"
                  >
                    <small class="p-error">{{
                      error.$message.replace(
                        "[value]",
                        $t("schemes.passPoints")
                      )
                    }}</small>
                  </span>
                </span>
                <small
                  v-else-if="
                    (v$.scheme.passPoints.$invalid && submitted) ||
                    v$.scheme.passPoints.$pending.$response
                  "
                  class="p-error"
                  >{{
                    v$.scheme.passPoints.required.$message.replace(
                      "[value]",
                      $t("schemes.passPoints")
                    )
                  }}</small
                > -->
              </div>
            </div>
          </div>
          <ul
            v-if="v$.scheme.passPoints.$error && submitted"
            class="p-error text-xs col-6 col-offset-6 pl-3"
          >
            <li
              v-for="(error, index) of v$.scheme.passPoints.$errors"
              :key="index"
            >
              {{ error.$message.replace("[value]", $t("schemes.passPoints")) }}
            </li>
          </ul>
        </div>
        <!--------------------------Score items ---------------------------------------------------------------------------- -->
        <span
          class="col-12 text-lg font-bold border-left-3 md:border-left-1 border-orange-500 pl-2 pb-2"
          style="margin-top: 0"
        >
          {{ $t("schemes.scoreItems") }}
        </span>
        <div
          class="grid grid-nogutter md:border-left-1 border-orange-500 pl-0 md:pl-2"
          v-for="(scoreItem, scoreItemIndex) in scheme.scoreItems"
          :key="scoreItemIndex"
        >
          <ScoreItems
            :schemeValue="scheme"
            :scoreItemValue="scoreItem"
            :scoreItemsValue="scheme.scoreItems"
            :indexValue="scoreItemIndex"
            :submittedValue="submitted"
          ></ScoreItems>
        </div>
        <div class="col-12 text-left">
          <Button
            class="p-button-sm bg-orange-500 border-none"
            :disabled="scheme.status"
            icon="mdi mdi-plus-circle-outline"
            iconPos="left"
            :label="$t('schemes.addScoreItem')"
            autofocus
            @click="addScoreItem()"
          />
        </div>
        <div class="col mt-4 py-0">
          <span>* {{ $t("global.mandatoryFields") }}</span>
        </div>
      </div>

      <template #footer>
        <Button
          :label="schemeId == null ? $t('global.create') : $t('global.update')"
          class="p-button-success"
          @click="create"
          autofocus
          :loading="loadingButton"
          iconPos="right"
        />
        <Button
          :label="$t('global.cancel')"
          @click="close"
          class="p-button-danger"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { required, minValue, maxValue } from "@/utilities/i18n-validators";
import { useVuelidate } from "@vuelidate/core";
import { FilterMatchMode } from "primevue/api";
// import { mapGetters } from "vuex";
import ScoreItems from "./evaluationCriteria/ScoreItems.vue";
import InstructionRule from "./instructionRules/InstructionRule.vue";

export default {
  name: "Add scheme",
  emits: ["editOpened", "closeAddSchemeDialog"],
  components: {
    ScoreItems,
    InstructionRule,
  },
  props: ["display", "edit", "schemeId", "schemeValue"],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      expiPeriod: "",
      expOptions: [
        {
          title: this.$t("schemes.oneYear"),
          value: "1-years",
        },
        {
          title: this.$t("schemes.twoYears"),
          value: "2-years",
        },
        {
          title: this.$t("schemes.threeYears"),
          value: "3-years",
        },
        {
          title: this.$t("schemes.fourYears"),
          value: "4-years",
        },
      ],
      scheme: {
        title: "",
        schemeTitle: "",
        schemeTitleEn: "",
        schemeTitleZh: "",
        expirationPeriod: "",
        instructionsForAssessmentLabel: "",
        instructionsForAssessment: [""],
        scoreRequirementLabel: "",
        scoreRequirement: "",
        assessmentCriteriaLabel: "",
        assessmentCriteria: "",
        scoreSheetLabel: "",
        scoreItems: [
          {
            title: "",
            points: 0,
            items: [
              {
                title: "",
                points: 0,
                assessments: [
                  {
                    title: "",
                    points: 0,
                    criteria: [
                      {
                        title: "",
                        scoreRatio: 0,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
        status: false,
      },
      showInstructionLabel: false,
      viewDialog: false,
      loadingButton: false,
      dropdownChoice: {},
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      submitted: false,
    };
  },
  validations() {
    return {
      scheme: {
        title: {
          required,
        },
        schemeTitle: {
          required,
        },
        schemeTitleEn: {
          required,
        },
        schemeTitleZh: {
          required,
        },
        expirationPeriod: {
          required,
        },
        instructionsForAssessmentLabel: {
          required,
        },
        scoreRequirementLabel: {
          required,
        },
        scoreRequirement: {
          required,
        },
        assessmentCriteriaLabel: {
          required,
        },
        assessmentCriteria: {
          required,
        },
        scoreSheetLabel: {
          required,
        },
        passPoints: {
          required,
          minValue: minValue(0),
          maxValue: maxValue(this.scheme.totalPoints),
        },
      },
      submitted: false,
    };
  },
  created() {},
  // computed: {
  //   ...mapGetters(["getScheme"]),
  // },
  mounted() {
    this.$emit("editOpened");
  },
  watch: {
    display(val) {
      this.viewDialog = val;
      console.log("run");
      if (this.schemeId != null) {
        console.log(this.schemeValue);
        this.scheme = Object.assign({}, this.schemeValue);
        this.scheme.status = this.scheme.status == "active" ? true : false;
      }
    },
    viewDialog(val) {
      // console.log(this.v$.scheme.scoreItems.$each);
      if (val == false) {
        this.close();
      } else {
        if (this.schemeId == null) {
          this.scheme = {
            title: "",
            schemeTitle: "",
            schemeTitleEn: "",
            schemeTitleZh: "",
            expirationPeriod: "",
            instructionsForAssessmentLabel: "",
            instructionsForAssessment: [""],
            scoreRequirementLabel: "",
            scoreRequirement: "",
            assessmentCriteriaLabel: "",
            assessmentCriteria: "",
            scoreSheetLabel: "",
            scoreItems: [
              {
                title: "",
                points: 0,
                items: [
                  {
                    title: "",
                    points: 0,
                    assessments: [
                      {
                        title: "",
                        points: 0,
                        criteria: [
                          {
                            title: "",
                            scoreRatio: 0,
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            status: false,
          };
        }
      }
    },
  },

  methods: {
    changeStatus(event) {
      if (this.scheme.status) {
        this.$confirm.require({
          target: event.currentTarget,
          message: this.$t("schemes.disableScheme"),
          icon: "pi pi-exclamation-triangle",
          acceptLabel: this.$t("global.yes"),
          rejectLabel: this.$t("global.no"),
          accept: () => {
            this.scheme.status = false;
          },
        });
      } else {
        this.scheme.status = true;
      }
    },
    close(val) {
      this.$emit("closeAddSchemeDialog", val);
      this.scheme = {};
      this.submitted = false;
    },

    async create() {
      this.submitted = true;
      console.log(this.scheme);
      const result = await this.v$.$validate();
      console.log(this.v$);
      if (result) {
        this.loadingButton = true;
        this.scheme.status = this.scheme.status == true ? "active" : "inactive";
        if (this.schemeId == null) {
          try {
            await this.$store
              .dispatch("createScheme", this.scheme)
              .then((res) => {
                this.$action("certification-scheme-create", {
                  schemeId: res.id,
                });
              });
            this.$notification(
              this.$t("schemes.addScheme"),
              this.$t("schemes.schemeCreated")
            );
            this.$notification(
              this.$t("schemes.addScheme"),
              this.$t("schemes.reloadTableMessage"),
              "info"
            );
            this.close(true);
            this.loadingButton = false;
          } catch (err) {
            // this.$notification(
            //   this.$t("schemes.addScheme"),
            //   this.$t("global.errorMessage"),
            //   "error"
            // );
            this.loadingButton = false;
          }
        } else {
          try {
            await this.$store.dispatch("updateSchema", this.scheme).then(() => {
              this.$action("certification-scheme-update");
            });

            this.$notification(
              this.$t("schemes.updateScheme"),
              this.$t("schemes.schemeUpdated")
            );

            this.$notification(
              this.$t("schemes.updateScheme"),
              this.$t("schemes.reloadTableMessage"),
              "info"
            );
            this.close(true);
            this.loadingButton = false;
          } catch (err) {
            // this.$notification(
            //   this.$t("schemes.updateScheme"),
            //   this.$t("global.errorMessage"),
            //   "error"
            // );
            this.loadingButton = false;
          }
        }
      }
    },
    addAssessmentInstruction() {
      this.scheme.instructionsForAssessment.push("");
    },
    refreshInstruction() {
      this.scheme.instructionsForAssessmentLabel = null;
      // this.scheme.instructionsForAssessment = [];
    },
    addInstruction() {
      this.scheme.instructionsForAssessmentLabel = "";
      this.scheme.instructionsForAssessment = [""];
    },
    addScoreRequirement() {
      this.scheme.scoreRequirementLabel = "";
      this.scheme.scoreRequirement = "";
    },
    refreshScoreRequiremntLabel() {
      this.scheme.scoreRequirementLabel = null;
      // this.scheme.scoreRequirement = null;
    },
    refreshScoreRequiremnt() {
      this.scheme.scoreRequirement = null;
      // this.scheme.scoreRequirement = null;
    },
    refreshScoreSheetLabel() {
      this.scheme.scoreSheetLabel = null;
    },
    addAssessmentCriteria() {
      this.scheme.assessmentCriteriaLabel = "";
      this.scheme.assessmentCriteria = "";
    },
    refreshAssessmentCriteriaLabel() {
      this.scheme.assessmentCriteriaLabel = null;
      // this.scheme.assessmentCriteria = null;
    },
    refreshAssessmentCriteria() {
      this.scheme.assessmentCriteria = null;
      // this.scheme.assessmentCriteria = null;
    },
    addScoreItem() {
      this.scheme.scoreItems.push({
        title: "",
        points: 0,
        items: [
          {
            title: "",
            points: 0,
            assessments: [
              {
                title: "",
                points: 0,
                criteria: [
                  {
                    title: "",
                    scoreRatio: 0,
                  },
                ],
              },
            ],
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
  .p-paginator-current {
    margin-left: auto;
  }
}

// #dropDownLabel {
//   position: relative;
//   padding-right: 0;
//   width: 20%;
// }

// #dropDownLabelContent {
//   position: absolute;
//   bottom: 30%;
// }

::v-deep(.p-datatable.p-datatable-pages) {
  .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }
}
</style>
